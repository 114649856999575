<template>
  <div id="updated-roster">
    <b-card>
      asdakdhakda
      <!-- <FullCalendar ref="FullCalendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar> -->
      <div v-if="filterData.status == 0 && $can('publish', 'Roster')">
        <b-button @click="publishAll" class="mt-3 d-block ml-auto" variant="primary">Publish All</b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      :title="roster.roster_id ? 'Update Roster' : 'Create New Roster'"
      size="lg"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="roster.roster_id ? 'Update' : 'Save'"
      :ok-disabled="roster.status == 1 || roster.roster_id ? !$can('update', 'Roster') : !$can('create', 'Roster')"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div v-if="roster.roster_id ? $can('update', 'Roster') : $can('create', 'Roster')" class="row">
          <div class="col-md-6" v-if="logedinUser.roles[0].name === 'superadmin'">
            <b-form-group
              label="Company"
              label-for="name-input"
              invalid-feedback="company is required"
              :state="nameState"
            >
              <v-select
                v-model="roster.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="companies"
                class="bg-light"
                placeholder="Select company"
                 @input="getCustomers"
              />
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Customer"
              label-for="name-input"
              invalid-feedback="Duty is required"
              :state="nameState"
            >
              <v-select
                v-model="roster.customer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="customers"
                class="bg-light"
                placeholder="Select customer"
              />
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Duty"
              label-for="name-input"
              invalid-feedback="Duty is required"
              :state="nameState"
            >
              <v-select
                v-model="roster.duty"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="duties"
                class="bg-light"
                placeholder="Select Duty"
              />
            </b-form-group>
          </div>
          <!-- <div class="col-md-6">
            <b-form-group
              label="Shift"
              label-for="name-input"
              invalid-feedback="Shift is required"
              :state="nameState"
            >
              <v-select
                v-model="roster.shift"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="shifts"
                class="bg-light"
                placeholder="Select Shift"
              />
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <b-form-group
              label="Start Time"
              label-for="name-input"
              invalid-feedback="Shift is required"
              :state="nameState"
            >
              <b-form-timepicker
                class="bg-light"
                v-model="roster.start_time"
                locale="en"
                now-button
                label-close-button="Set"
              ></b-form-timepicker>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="End Time"
              label-for="name-input"
              invalid-feedback="Shift is required"
              :state="nameState"
            >
              <b-form-timepicker
                class="bg-light"
                v-model="roster.end_time"
                locale="en"
                now-button
                label-close-button="Set"
              ></b-form-timepicker>
            </b-form-group>
          </div>
        </div>
        <div v-else>
          <h4 v-if="roster.roster_id" class="my-2">You do not have permission to edit this roster!</h4>
          <h4 v-else class="my-2npm ">You do not have permission to create this roster!</h4>
        </div>
      </form>
    </b-modal>

    <!-- publish modal -->
    <b-modal id="modal-1" ref="publishModal"
      title="Publish Roster"
      size="sm"
      centered
      @show="resetPublishModal"
      @hidden="resetPublishModal"
      @ok="handleOkPublish"
      ok-title="Publish"
      :ok-disabled="!$can('publish', 'Roster')"
    >
      <h4 v-if="$can('publish', 'Roster')" class="my-2">Are you sure to publish this roster!</h4>
      <h4 v-else class="my-2">You do not have permission to publish this roster!</h4>
    </b-modal>
  </div>
</template>
<script>
import store from "@/store";
import { getToken } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { isEmpty } from "@/utils/validate";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/vue";
import axios from "axios";
import { BButton, BCard, BFormGroup, BFormInput, BFormTimepicker } from "bootstrap-vue";
import vSelect from "vue-select";
import { getLogedInUser } from "@/utils/auth";
import { VUE_APP_BASE_URL } from "@/utils/var";

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BButton,
    FullCalendar, // make the <FullCalendar> tag available
    vSelect,
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clicked: "edit",
      duties: [],
      customers: [],
      companies: [],
      shifts: [],
      logedinUser: JSON.parse(getLogedInUser()),
      query: {
        role: "admin",
        company: null,
        customer: null,
        user_id: null,
        customer_id: null,
      },
      roster: {
        company: null,
        duty: null,
        shift: null,
        customer: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
      },
      publishData: {
        status: 1,
        id: [],
      },
      nameState: null,
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "resourceTimelineTenDay resourceTimelineFiftenDay resourceTimelineThirtyDay",
        },
        initialView: "resourceTimelineTenDay",
        aspectRatio: 1.5,
        resourceAreaColumns: [
          {
            field: "title",
            headerContent: "Employee List",
          },
        ],
        eventTimeFormat: {
          date: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
        views: {
          resourceTimelineTenDay: {
            type: "resourceTimeline",
            duration: { days: 7 },
            dateIncrement: { weeks: 1 },
            buttonText: "7 Days",
            slotDuration: { days: 1 },
          },
          resourceTimelineFiftenDay: {
            type: "resourceTimeline",
            duration: { days: 15 },
            dateIncrement: { weeks: 2 },
            buttonText: "15 Days",
            slotDuration: { days: 1 },
          },
          resourceTimelineThirtyDay: {
            type: "resourceTimeline",
            duration: { days: 30 },
            dateIncrement: { months: 1 },
            buttonText: "Month",
            slotDuration: { days: 1 },
          },
        },
        slotLabelFormat: [
          { weekday: "short", year: "numeric", month: "short", day: "numeric" },
        ],
        slotLabelInterval: { days: 1 },
        eventClick(info) {
          let self = this.el.__vue__;
          if (window.clickedOn == "edit") {
            self.$bvModal.show("modal-prevent-closing");
            // assign event to modal
            window.clickedOn = "";
          }
          if(window.clickedOn == "publish"){
            self.$bvModal.show("modal-1");
            window.clickedOn = "";
          }
          let startStr = new Date(info.event.startStr).toTimeString();
          let endStr = new Date(info.event.endStr).toTimeString();

          if (info.event.title == "Add Roster") {
            self.$bvModal.show("modal-prevent-closing");
            self.$parent.roster.date = info.event.startStr;
            self.$parent.roster.employee_id = info.event._def.resourceIds[0];
            self.$parent.roster.company = self.$parent.filterData.company;
            self.$parent.roster.customer = self.$parent.filterData.customer;
          } else {
            self.$parent.roster.date = info.event.startStr.substring(
              0,
              info.event.startStr.indexOf("T")
            );
            self.$parent.roster.employee_id = info.event.id;
          }

          if (info.event.title !== "Add Roster") {
            self.$parent.roster.company = self.$parent.companies.find(
              (company) => company.id == info.event.extendedProps.user_id
            );
          }

          if(info.event.extendedProps.user_id){
            self.$parent.query.user_id = info.event.extendedProps.user_id;
            self.$parent.getCustomers();
          }

          self.$parent.roster.roster_id = info.event.extendedProps.roster_id;
          self.$parent.roster.duty = info.event.extendedProps.duty;
          self.$parent.roster.shift = info.event.extendedProps.shift;
          self.$parent.roster.status = info.event.extendedProps.status;

          if (info.event.title !== "Add Roster" && self.$parent.customers.length > 0) {
            // setTimeout(() => {
              self.$parent.roster.customer = self.$parent.customers.find(
                (customer) => customer.id == info.event.extendedProps.customer
              );
            // }, 500);
          }
          
          
          
          self.$parent.roster.start_time = startStr.substring(
            0,
            startStr.indexOf(" GMT")
          );
          self.$parent.roster.end_time = endStr.substring(
            0,
            endStr.indexOf(" GMT")
          );
        },
        dateClick(info) {
          // get vue instance
          // let self = this.el.__vue__;
          // self.$bvModal.show("modal-prevent-closing");
          // self.$parent.roster.date = info.dateStr;
          // self.$parent.roster.employee_id = info.resource.id;
        },
        //update event
        eventDrop: function (info) {
          alert(
            `Event Drop <> Employee ID: ${info?.event?.id}-${info?.event?.title} <> Date: ${info?.event?.startStr}`
          );
        },
        events: {
          url: `${VUE_APP_BASE_URL}/roster/event`,
          method: "GET",
          extraParams: {
            type: "event",
            Authorization: `Bearer ${getToken()}`,
            status: 0,
            user_id : "",
            customer_id : "",
            // sector: 0,
            // 'role': 'customer',
          },
        },
        resources: {
          url: `${VUE_APP_BASE_URL}/roster/resource`,
          method: "GET",
          extraParams: {
            Authorization: `Bearer ${getToken()}`,
            //'role': 'customer',
            sector: "",
          },
        },
        eventDidMount: function (info) {
          info.el.style.margin = "5px";
          if (info.event?.title == "Add Roster") {
            if (info.event?.extendedProps?.count == 0) {
              info.el.style.backgroundColor = "#f9f9f9";
              info.el.style.padding = "55px";
              let title = document.createElement("span");
              let icon = document.createElement("i");
              icon.style.textAlign = "center";
              icon.style.fontSize = "14px";
              icon.style.color = "#A8ADAF";
              icon.innerHTML = `Unallocated`;
              title.appendChild(icon);
              info.el.prepend(title);
              info.el.querySelector(".fc-event-main").style.display =
                "none !important";
            }
          } else {
            let main = info.el.querySelector(".fc-event-main");
            main.style.width = "100%";
            main.style.textAlign = "end";
            info.el.style.padding = "20px 2px";
            let div = document.createElement("div");
            div.style.marginTop = "5px";
            div.style.display = "flex";
            div.style.justifyContent = "space-between";
            // div.style.alignItems = "center";
            div.style.width = "100%";
            let div1 = document.createElement("div");
            

            div1.classList.add("duty");
            div1.style.marginTop = "5px";
            div1.innerHTML = info.event.extendedProps.duty
              ? info.event.extendedProps.duty.name
              : "N/A";

            if(info.event.extendedProps.status == 0){
              let edit = document.createElement("div");
              edit.innerHTML = `<button type="button" onclick="window.clickedOn = 'edit'" class="btn btn-sm btn-light text-primary custom">Edit</button>`;

              let publish = document.createElement("div");
              publish.innerHTML = `<button type="button" onclick="window.clickedOn = 'publish'" class="btn btn-sm btn-light text-primary custom">Publish</button>`;

              div.append(publish, edit);
            }
            
            info.el.append(div1, div);
          }
        },
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resourceAreaWidth: "15%",
        resourceLabelDidMount: function (info) {
          let find = info.el.querySelector(".fc-datagrid-cell-cushion");
          //find.classList.add('labelLane');
          let div2 = document.createElement("span");
          div2.classList.add("fc-datagrid-cell-main");
          div2.classList.add("id");
          let div = document.createElement("span");
          let div3 = document.createElement("span");
          div3.classList.add("fc-datagrid-cell-main");
          div3.classList.add("name");
          let img = document.createElement("img");
          img.style.height = "50px";
          img.style.width = "50px";
          img.style.borderRadius = "50%";
          img.style.border = "1px solid #000";
          img.style.objectFit = "cover";
          img.style.objectPosition = "center";
          div2.innerHTML = "ID" + info.resource.id;
          div3.innerHTML = info.resource.extendedProps.position
            ? info.resource.extendedProps.position
            : "N/A";
          img.src = info.resource.extendedProps.img.includes(
            "https://ui-avatars.com"
          )
            ? info.resource.extendedProps.img
            : store.state.appConfig.imageBaseUrl +
              info.resource.extendedProps.img;
          div.innerHTML = find.prepend(img, div2);
          div.innerHTML = find.append(div3);
          // return { domNodes: [div] };
        },
      },
    };
  },
  mounted() {
    this.getDuties();
    this.getCompanies();
    this.getShifts();
  },
  methods: {
    async getDuties() {
      let res = await this.$store.dispatch("duty/getDuties");
      this.duties = res.data.data;
    },
    async getShifts() {
      let res = await this.$store.dispatch("shift/getShifts");
      this.shifts = res.data;
    },
    async getCompanies() {
      this.$store
        .dispatch("roster/rosterFilter", this.query)
        .then(({ data }) => {
          this.companies = data;
          if(this.companies.length > 0){
            this.roster.company = this.companies[0];
            this.roster.user_id = this.companies[0].id;
          }
          if (this.logedinUser.roles[0].name === "admin") {
            this.roster.company = this.companies.find(
              (company) => company.id === this.logedinUser.id
            );
            this.roster.user_id = this.logedinUser.id;
          }
          if(this.roster.user_id){
            this.getCustomers();
          }
        });

    },
    async getCustomers() {
      this.query.user_id = this.roster.company?.id;
      this.roster.user_id = this.roster.company?.id;
      await this.$store
        .dispatch("roster/rosterFilter", this.query)
        .then((response) => {
          this.customers = response;
        });
    },
    resetModal() {
      this.roster = {
        duty: null,
        shift: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
      };
      console.log(this.roster, 'reset');
    },
    
    handleOkPublish(){
      this.publishData.id.push(this.roster.roster_id);
      this.handlePublish();
    },
    handlePublish() {
      if(this.publishData.id.length > 0){
        this.$store.dispatch("roster/publishRoster", this.publishData).then((res) => {
          if (res.status_code == 200) {
            toast(
              "Success",
              "CheckIcon",
              "success",
              "Roster published successfully"
            );
            this.resetPublishModal();
            this.$refs.FullCalendar.getApi().refetchEvents();
            this.$refs.publishModal.hide();
          }
        }).catch((err) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Something went wrong"
          );
        })
      }
    },
    resetPublishModal(){
      this.publishData = {
        status: 1,
        id: [],
      }
    },
    publishAll(){
      axios.get("https://grypas.inflack.xyz/grypas-api/api/v1/roster/event", {
        params: {
          type: "event",
          Authorization: `Bearer ${getToken()}`,
          user_id: this.filterData.user_id ? this.filterData.user_id : 0,
          customer_id: this.filterData.customer_id ? this.filterData.customer_id : 0,
          // start time today
          start: new Date().toISOString(),
          // end time 7 days from today
          end: new Date(
            new Date().getTime() + 7 * 24 * 60 * 60 * 1000
          ).toISOString(),
          status: 0,
        },
      }).then((res) => {
        let data = res.data.filter((event) => event.title != "Add Roster");
        data.forEach((event) => {
          // roster_id already exist in publishData.id array
          if(this.publishData.id.includes(event.extendedProps.roster_id)){
            return;
          }
          this.publishData.id.push(event.extendedProps.roster_id);
        });
        this.handlePublish();
      });
    },
    async handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      this.roster.duty_id = this.roster.duty ? this.roster.duty.id : "";
      this.roster.shift = this.shifts.length > 0 ? this.shifts[0] : {};
      this.roster.shift_id = this.roster.shift ? this.roster.shift.id : "";
      this.roster.customer_id = this.roster.customer
        ? this.roster.customer.id
        : "";
      this.roster.user_id = this.roster.company
        ? this.roster.company.id
        : JSON.parse(this.$store.state.auth.user).id;

      // remove roster_id if empty for create new roster for empty roster_id validation 
      if (!this.roster.roster_id) {
        delete this.roster.roster_id;
      }

      if (isEmpty(this.roster)) {
        toast(
          "Validation Error",
          "AlertTriangleIcon",
          "danger",
          "Please fill all the roster fields"
        );
      } else {
        this.eventValidate();
      }
    },
    eventValidate() {
      let query = {
        type: "event",
        // start time today
        start: new Date(this.roster.date).toISOString(),
        // end time 1 day after start time
        end: new Date(
          new Date(this.roster.date).getTime() + 24 * 60 * 60 * 1000
        ).toISOString(),
        employee_id: this.roster.employee_id,
        Authorization: `Bearer ${getToken()}`,
      };
      axios
        .get("https://grypas.inflack.xyz/grypas-api/api/v1/roster/event", {
          params: query,
        })
        .then((res) => {
          let data = res.data;
          // get target employee events on selected date
          let events = data.filter(
            (event) => event.resourceId == this.roster.employee_id
          );

          // remove empty events from array
          events = events.filter((event) => event.title != "Add Roster");

          // remove current event from array if edit
          if (this.roster.roster_id) {
            let index = events.findIndex(
              (event) => event.extendedProps.roster_id == this.roster.roster_id
            );
            if (index > -1) {
              events.splice(index, 1);
            }
          }

          // check if events array is not empty
          if (events.length > 0) {
            let hasError = false;
            events.forEach((event) => {
              let start = new Date(event.start);
              let end = new Date(event.end);
              let start_time = new Date(
                this.roster.date + " " + this.roster.start_time
              );
              let end_time = new Date(
                this.roster.date + " " + this.roster.end_time
              );
              // roster date block is already in selected date block
              if (start_time <= start && end_time >= end && !hasError) {
                toast(
                  "Validation Error",
                  "AlertTriangleIcon",
                  "danger",
                  "Roster date block is already in selected date block"
                );
                hasError = true;
              } else if (
                start_time >= start &&
                start_time <= end &&
                !hasError
              ) {
                toast(
                  "Validation Error",
                  "AlertTriangleIcon",
                  "danger",
                  "Start time already exist"
                );
                hasError = true;
              } else if (end_time >= start && end_time <= end && !hasError) {
                toast(
                  "Validation Error",
                  "AlertTriangleIcon",
                  "danger",
                  "End time already exist"
                );
                hasError = true;
              } else {
                if (!hasError) {
                  this.handleSubmit();
                }
              }
            });
          } else {
            this.handleSubmit();
          }
        })
        .catch((err) => {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Something went wrong!"
          );
          console.log(err);
        });
    },
    handleSubmit() {
      let data = {
        duty_id: this.roster.duty_id,
        shift_id: this.roster.shift_id,
        customer_id: this.roster.customer_id,
        user_id: this.roster.user_id,
        employee_id: Number(this.roster.employee_id),
        date: this.roster.date,
        times: [
          {
            id: this.roster.roster_id ? this.roster.roster_id : null,
            duty_id: this.roster.duty_id ? this.roster.duty_id : null,
            start_time: this.roster.start_time,
            end_time: this.roster.end_time,
          },
        ],
      };
      this.$store
        .dispatch("roster/createRoster", data)
        .then((res) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.$refs.FullCalendar.getApi().refetchEvents();
          this.resetModal();
          toast(
            "Roster Created",
            "CheckCircleIcon",
            "success",
            "Roster has been created successfully"
          );
        })
        .catch((err) => {
          console.log(err);
          toast(
            "Roster Created",
            "CheckCircleIcon",
            "success",
            err.response?.data?.message || "Something went wrong!"
          );
        });
    },
    reCall(){
      this.calendarOptions.events.extraParams.user_id = this.filterData.user_id;
      this.calendarOptions.events.extraParams.customer_id = this.filterData.customer_id;
      // this.calendarOptions.events.extraParams.status = this.filterData.status;
      this.calendarOptions.events.extraParams.sector = this.filterData.sector_id;
      this.calendarOptions.resources.extraParams.sector = this.filterData.sector_id;
      this.calendarOptions.resources.extraParams.user_id = this.filterData.user_id;
      this.calendarOptions.resources.extraParams.customer_id = this.filterData.customer_id;
      this.calendarOptions.resources.extraParams.employee_id = this.filterData.employee_id;
      this.calendarOptions.events.extraParams.employee_id = this.filterData.employee_id;
      this.$refs.FullCalendar.getApi().refetchEvents();
      this.$refs.FullCalendar.getApi().refetchResources();
    }
  },
  watch: {
    filterData: {
      handler: function (val) {
        console.log(val, 'filterData');
        // refresh calendar events on filter change with new extra params
        this.calendarOptions.events.extraParams.user_id = val.user_id;
        this.calendarOptions.events.extraParams.customer_id = val.customer_id;
        // this.calendarOptions.events.extraParams.status = val.status;
        this.calendarOptions.events.extraParams.sector = val.sector_id;
        this.calendarOptions.resources.extraParams.sector = val.sector_id;
        this.calendarOptions.resources.extraParams.user_id = val.user_id;
        this.calendarOptions.resources.extraParams.customer_id = val.customer_id;
        this.$refs.FullCalendar.getApi().refetchEvents();
        this.$refs.FullCalendar.getApi().refetchResources();
      },
      deep: true,
    },
  }
};
</script>
<style scoped>
.fc-license-message {
  display: none;
}
.timeline-event {
  background-color: #f00;
  color: #000;
  border: 1px solid #f00;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}
.fc-datagrid-cell-frame:last-child {
  border: 1px solid #bebebe;
}
.fc-timeline-lane-frame {
  border: 1px solid #bebebe;
}

.labelLane {
  align-items: center !important;
  border-radius: 0;
  display: flex !important;
  /*font-size: var(--fc-small-font-size);*/
  margin-bottom: 1px !important;
  padding: 2px 1px !important;
  position: relative !important;
  font-weight: bold;
}

.timeline-event {
  display: flex;
  align-items: center;
}
.fc-timeline-events .fc-event-main i,
.fc-timeline-events .fc-event-main {
  color: #a8adaf !important;
  text-align: center;
  display: none;
}

.fc-timeline-events {
  height: 100% !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
